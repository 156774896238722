import { GoogleTagManager } from "@next/third-parties/google";

import type { ReactElement, ReactNode } from "react";
// import { useState } from "react";

// import {
//   Hydrate,
//   QueryClient,
//   QueryClientProvider,
// } from "@tanstack/react-query";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";

import "@/styles/globals.css";
import { theme } from "@/styles/theme";

import { inter, monda, montserrat } from "@/utils/fonts";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout || ((page) => page);
  return getLayout(
    <>
      <div
        className={`${montserrat.variable} ${inter.variable} ${monda.variable} font-montserrat overflow-hidden`}
      >
        <NextNProgress color={theme.colors.primary[900]} />
        <Component {...pageProps} />
      </div>
      <GoogleTagManager gtmId="G-277GYZEKQN" />
    </>
  );
}
